import { accountUrl, cashbankUrl, financeUrl, getHeader, timeoutAPP } from "@/constant/config";
import axios from "axios";

export default {
  state: {
    accountTypeList: [],
    accountType: {},
    accountList: [],
    account: {},
    cashBankType: {},
    cashBankTypeList: [],
    cashBank: {
      id: null,
      cash_no: null,
      type_id: null,
      cash_date: null,
      debit: '0',
      credit: '0',
      remark: null,
      org_no: null,
      account_code: null,
      journal_code: null,
      notes: null,
      ref_no: null,
      status: null,
      lock: false,
    },
    journal: {},
    journalTemplate: {
      id: null,
      name: null,
      template_date: null,
      description: null,
      org_no: null,
      type: null,
      details: [{
        id: null,
        template_id: null,
        account_code: null,
        remark: null,
        method: null,
        formula: null,
        org_no: null
      }]
    },
    activeModalAccount: false,
    activeModalAccountEdit: false,
    activeModalAccountType: false,
    activeModalAccountTypeEdit: false,
    activeModalCashBank: false,
    activeModalCashBankEdit: false,
    activeModalCashBankType: false,
    activeModalCashBankTypeEdit: false,
    activeModalImportBank: false,
    activeModalImportFormBank: false,
    activeModalAddJournalTemplate: false,
    activeModalEditJournalTemplate: false,
    activeShowJournalModal: false,
    activeModalProcessPnL: false,
    acitveProfitLossTemplateModal: false,
    acitveBalanceTemplateModal: false
  },
  mutations: {
    SET_ACTIVE_MODAL_ADD_JOURNAL_TEMPLATE (state, obj) {
      state.activeModalAddJournalTemplate = obj
    },
    SET_ACTIVE_MODAL_EDIT_JOURNAL_TEMPLATE (state, obj) {
      state.activeModalEditJournalTemplate = obj
    },
    SET_ACTIVE_JOURNAL_SHOW_MODAL (state, obj) {
      state.activeShowJournalModal = obj
    },
    SET_ACTIVE_PROCESS_PNL (state, obj) {
      state.activeModalProcessPnL = obj
    },
    SET_ACTIVE_IMPORT_BANK (state, obj) {
      state.activeModalImportBank = obj
    },
    SET_ACTIVE_IMPORT_FORM_BANK (state, obj) {
      state.activeModalImportFormBank = obj
    },
    SET_ACTIVE_CASH_BANK (state, obj) {
      state.activeModalCashBank = obj
    },
    SET_ACTIVE_CASH_BANK_EDIT (state, obj) {
      state.activeModalCashBankEdit = obj
    },
    SET_CASH_BANK_TYPE (state, obj) {
      state.cashBankType = obj
    },
    SET_CASH_BANK_TYPE_LIST (state, obj) {
      state.cashBankTypeList = obj
    },
    SET_CASH_BANK (state, obj) {
      state.cashBank = obj
    },
    SET_ACTIVE_CASH_BANK_TYPE (state, obj) {
      state.activeModalCashBankType = obj
    },
    SET_ACTIVE_CASH_BANK_TYPE_EDIT (state, obj) {
      state.activeModalCashBankTypeEdit = obj
    },
    SET_ACCOUNT_TYPE_LIST (state, obj) {
      state.accountTypeList = obj
    },
    SET_ACCOUNT_LIST (state, obj) {
      state.accountList = obj
    },
    SET_JOURNAL (state, obj) {
      state.journal = obj
    },
    SET_ACTIVE_MODAL_ACCOUNT (state, obj) {
      state.activeModalAccount = obj
    },
    SET_ACTIVE_MODAL_ACCOUNT_EDIT (state, obj) {
      state.activeModalAccountEdit = obj
    },
    SET_ACTIVE_MODAL_ACCOUNT_TYPE (state, obj) {
      state.activeModalAccountType = obj
    },
    SET_ACTIVE_MODAL_ACCOUNT_TYPE_EDIT (state, obj) {
      state.activeModalAccountTypeEdit = obj
    },
    SET_ACCOUNT_TYPE (state, obj) {
      state.accountType = obj
    },
    SET_ACCOUNT (state, obj) {
      state.account = obj
    },
    SET_JOURNAL_TEMPLATE (state, obj) {
      state.journalTemplate = obj
    },
    SET_ACTIVE_PROFIT_LOSS_TEMPLATE_MODAL (state, obj) {
      state.acitveProfitLossTemplateModal = obj
    },
    SET_ACTIVE_BALANCE_TEMPLATE_MODAL (state, obj) {
      state.activeBalanceTemplateModal = obj
    }
  },
  actions: {
    enableBalanceTemplateModal ({ commit }) {
      commit('SET_ACTIVE_BALANCE_TEMPLATE_MODAL', true)
    },
    disableBalanceTemplateModal ({ commit }) {
      commit('SET_ACTIVE_BALANCE_TEMPLATE_MODAL', false)
    },
    enableProfitLosstTemplateModal ({ commit }) {
      commit('SET_ACTIVE_PROFIT_LOSS_TEMPLATE_MODAL', true)
    },
    disableProfitLosstTemplateModal ({ commit }) {
      commit('SET_ACTIVE_PROFIT_LOSS_TEMPLATE_MODAL', false)
    },
    async getJournalTemplate ({ commit }, payload) {
      try {
        const data = await axios.get(financeUrl + '/journal/template/' + payload.id, { timeout: timeoutAPP, headers: getHeader() })
        commit('SET_JOURNAL_TEMPLATE', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
      }
    },
    async getJournal ({ commit }, payload) {
      try {
        const data = await axios.get(financeUrl + '/journal/' + payload.id, { headers: getHeader() })
        commit('SET_JOURNAL', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    activeModalEditJournalTemplate ({ commit }) {
      commit('SET_ACTIVE_MODAL_EDIT_JOURNAL_TEMPLATE', true)
    },
    deactiveModalEditJournalTemplate ({ commit }) {
      commit('SET_ACTIVE_MODAL_EDIT_JOURNAL_TEMPLATE', false)
    },
    activeShowJornalModal ({ commit }) {
      commit('SET_ACTIVE_JOURNAL_SHOW_MODAL', true)
    },
    deactiveShowJournalModal ({ commit }) {
      commit('SET_ACTIVE_JOURNAL_SHOW_MODAL', false)
    },
    activeModalProcessPnL ({ commit }) {
      commit('SET_ACTIVE_PROCESS_PNL', true)
    },
    deactiveModalProcessPnL ({ commit }) {
      commit('SET_ACTIVE_PROCESS_PNL', false)
    },
    activeModalAddJournalTemplate ({ commit }) {
      commit('SET_ACTIVE_MODAL_ADD_JOURNAL_TEMPLATE', true)
    },
    deactiveModalAddJournalTemplate ({ commit }) {
      commit('SET_ACTIVE_MODAL_ADD_JOURNAL_TEMPLATE', false)
    },
    activeModalImportBank ({ commit }) {
      commit('SET_ACTIVE_IMPORT_BANK', true)
    },
    deactiveModalImportBank ({ commit }) {
      commit('SET_ACTIVE_IMPORT_BANK', false)
    },
    activeModalImportFormBank ({ commit }) {
      commit('SET_ACTIVE_IMPORT_FORM_BANK', true)
    },
    deactiveModalImportFormBank ({ commit }) {
      commit('SET_ACTIVE_IMPORT_FORM_BANK', false)
    },
    async getCashBank ({ commit }, payload) {
      try {
        const data = await axios.get(financeUrl + '/cashbank/' + payload.id, { headers: getHeader() })
        commit('SET_CASH_BANK', data.data.data)
        console.log(data.data.message)
      } catch (error) {
        console.log(error)
        console.log(error.message)
      }
    },
    activeModalCashBank ({ commit }) {
      commit('SET_ACTIVE_CASH_BANK', true)
    },
    deactiveModalCashBank ({ commit }) {
      commit('SET_ACTIVE_CASH_BANK', false)
    },
    activeModalCashBankEdit ({ commit }) {
      commit('SET_ACTIVE_CASH_BANK_EDIT', true)
    },
    deactiveModalCashBankEdit ({ commit }) {
      commit('SET_ACTIVE_CASH_BANK_EDIT', false)
    },
    async getCashBankType ({ commit}, payload) {
      try {
        const data = await axios.get(cashbankUrl + '/type/' + payload.id, { headers: getHeader() })
        commit('SET_CASH_BANK_TYPE', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
      }
    },
    async getCashBankTypeList ({ commit }) {
      try {
        const data = await axios.get(financeUrl + '/cashbank/type', { headers: getHeader() })
        commit('SET_CASH_BANK_TYPE_LIST', data.data.data)
        console.log(data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
      }
    },
    activeModalCashBankType ({ commit }) {
      commit('SET_ACTIVE_CASH_BANK_TYPE', true)
    },
    deactiveModalCashBankType ({ commit }) {
      commit('SET_ACTIVE_CASH_BANK_TYPE', false)
    },
    activeModalCashBankTypeEdit ({ commit }) {
      commit('SET_ACTIVE_CASH_BANK_TYPE_EDIT', true)
    },
    deactiveModalCashBankTypeEdit ({ commit }) {
      commit('SET_ACTIVE_CASH_BANK_TYPE_EDIT', false)
    },
    async getAccountType ({ commit }, payload) {
      try {
        const data = await axios.get(accountUrl + '/type/' + payload.id, { headers: getHeader() })
        commit('SET_ACCOUNT_TYPE', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
      }
    },
    async getAccount ({ commit }, payload) {
      try {
        const data = await axios.get(accountUrl + '/' + payload.id, { headers: getHeader() })
        commit('SET_ACCOUNT', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
      }
    },
    activeModalAccount ({ commit }) {
      commit('SET_ACTIVE_MODAL_ACCOUNT', true)
    },
    dectiveModalAccount ({ commit }) {
      commit('SET_ACTIVE_MODAL_ACCOUNT', false)
    },
    activeModalAccountEdit ({ commit }) {
      commit('SET_ACTIVE_MODAL_ACCOUNT_EDIT', true)
    },
    dectiveModalAccountEdit ({ commit }) {
      commit('SET_ACTIVE_MODAL_ACCOUNT_EDIT', false)
    },
    activeModalAccountType ({ commit }) {
      commit('SET_ACTIVE_MODAL_ACCOUNT_TYPE', true)
    },
    dectiveModalAccountType ({ commit }) {
      commit('SET_ACTIVE_MODAL_ACCOUNT_TYPE', false)
    },
    activeModalAccountTypeEdit ({ commit }) {
      commit('SET_ACTIVE_MODAL_ACCOUNT_TYPE_EDIT', true)
    },
    deactiveModalAccountTypeEdit ({ commit }) {
      commit('SET_ACTIVE_MODAL_ACCOUNT_TYPE_EDIT', false)
    },
    async getAccountTypeList ({ commit }) {
      try {
        const data = await axios.get(accountUrl + '/type', { headers: getHeader() })
        commit('SET_ACCOUNT_TYPE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
      }
    },
    async getAccountList ({ commit }) {
      try {
        const data = await axios.get(accountUrl, { headers: getHeader() })
        commit('SET_ACCOUNT_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
      }
    }
  },
  getters: {
    cashBank: (state) => {
      const data = state.cashBank
      let debit = 0
      if (data.debit === null) {
        debit = '0'
      } else if (typeof data.debit === 'string') {
        debit = parseFloat(data.debit).toLocaleString('id-ID')
      } else {
        debit = data.debit.toLocaleString('id-ID')
      }
      let credit = 0
      if (data.credit === null) {
        credit = '0'
      } else if (typeof data.credit === 'string') {
        credit = parseFloat(data.credit).toLocaleString('id-ID')
      } else {
        credit = data.credit.toLocaleString('id-ID')
      }
      return {
        // id: data.id,
        // cash_no: data.cash_no,
        // type_id: data.type_id,
        // cash_date: data.cash_date,
        // debit: debit,
        // credit: credit,
        // remark: data.remark,
        // org_no: data.org_no,
        // account_code: data.account_code,
        // journal_code: data.journal_code,
        // notes: data.notes,
        // ref_no: data.ref_no,
        // status: data.status,
        // lock: data.lock
        id: data.id,
        type_id: data.type_id,
        cash_date: data.cash_date,
        contact_no: data.contact_no,
        debit: debit,
        credit: credit,
        remark: data.remark,
        account_id: data.account_id,
        journal_code: data.journal_code,
        notes: data.notes,
        ref_no: data.ref_no,
        status: data.status,
        lock: data.lock,
        org_no: data.org_no,
        is_journal: data.is_journal,
        ref_id: data.ref_id,
        is_detail: data.is_detail,
        category: data.category,
        group: data.group,
        branch_no: data.branch_no,
        round: data.round,
        details: data.details
      }
    },
    accountTypeList: (state) => {
      const data = state.accountTypeList
      const loop = []
      for (let i = 0; i < data.length; i++) {
        loop.push({
          // id: data[i].id,
          // level: data[i].level,
          // type_code: data[i].type_code,
          // account_code: data[i].account_code,
          // account_name: data[i].account_name,
          // notes: data[i].notes,
          // group: data[i].group,
          // method: data[i].method,
          // org_no: data[i].org_no,
          // active: data[i].active,
          // loader: false

          id: data[i].id,
          parent_code: data[i].parent_code,
          type_code: data[i].type_code,
          name: data[i].name,
          org_no: data[i].org_no,
          branch_no: data[i].branch_no,
          loader: false
        })
      }
      return loop
    },
    accountList: (state) => {
      const data = state.accountList
      const loop = []
      for (let i = 0; i < data.length; i++) {
        loop.push({
          id: data[i].id,
          level: data[i].level,
          type_code: data[i].type_code,
          account_code: data[i].account_code,
          account_name: data[i].account_name,
          notes: data[i].notes,
          group: data[i].group,
          method: data[i].method,
          org_no: data[i].org_no,
          active: data[i].active,
          loader: false
        })
      }
      return loop
    }
  }
}
  